import logoAltImg from 'src/images/logo-wildlife-coaching.svg'
import React from 'react'
import Box from '@local/shared/components/System/Box'
import { Typography } from '@local/shared/components/System'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { Link } from 'gatsby'

const EMAIL = 'hello@wildlife.coach'

const Footer = () => (
  <Box
    as={'footer'}
    display={'flex'}
    flexDirection={{ xs: 'column', sm: 'row' }}
    bgcolor={'green.0'}
    justifyContent={'space-between'}
    alignItems={{ xs: 'center', sm: 'center' }}
    p={'1em'}
  >
    <Box
      as={Link}
      to={'/'}
      height={'4em'}
      mr={{ xs: 0, vs: '1em' }}
      mb={{ xs: '0.5em', sm: 0 }}
    >
      <Box height={'100%'} as={'img'} src={logoAltImg} alt={'WildLife Logo'} />
    </Box>
    <Box textAlign={{ xs: 'center', sm: 'right' }}>
      <OutboundLink href={`mailto:${EMAIL}`}>
        <Typography variant={'note'} mb={0}>
          {EMAIL}
        </Typography>
      </OutboundLink>
      <Typography variant={'note'} mb={0}>
        &copy; WildLife Coaching {new Date().getFullYear()}
      </Typography>
      <Typography variant={'note'} mb={0}>
        Website by{' '}
        <OutboundLink href={'http://savvasnicholas.com'}>
          Savvas Nicholas
        </OutboundLink>
      </Typography>
    </Box>
  </Box>
)

export default Footer
