import React from 'react'
import { Box } from '../System'
import { Link } from 'gatsby'
import Typography from '../System/Typography'
import styled from 'styled-components'

const NavItemStyled = styled(Typography)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text['0']};
  &:hover {
    text-decoration: underline;
  }
  font-size: ${({ theme }) => theme.typography.scaleToFontSize(1)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${({ theme }) => theme.typography.scaleToFontSize(2)};
  }
`

const NavItem = ({ name, to }) => (
  <Link to={to}>
    <NavItemStyled variant={'h6'} display={'inline-block'} ml={'1em'} mb={0}>
      {name}
    </NavItemStyled>
  </Link>
)

const Header = ({ logo, bgcolor }) => {
  const Logo = logo
  return (
    <Box
      as={'nav'}
      display={'flex'}
      height={'4em'}
      py={'0.5em'}
      px={'0.5em'}
      bgcolor={bgcolor}
    >
      <Box
        as={Link}
        to={'/'}
        mr={'1em'}
        display={'block'}
        height={'100%'}
        position={'relative'}
      >
        <Logo />
      </Box>
      <Box alignSelf={'center'}>
        <NavItem name={'Coaching'} to={'/'} />
        <NavItem name={'Blog'} to={'/blog'} />
      </Box>
    </Box>
  )
}

export default Header
