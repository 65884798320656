import React, { useEffect, useRef } from 'react'
import Box from '@local/shared/components/System/Box'
import { CSSPlugin, Elastic, gsap, TweenMax } from 'gsap/all'

gsap.registerPlugin(CSSPlugin)

const LogoImg = props => {
  const flowerRef = useRef(null)
  useEffect(() => {
    TweenMax.to(flowerRef.current, 10, {
      ease: Elastic.easeInOut,
      rotation: 360 * 5,
      transformOrigin: '50% 54%',
      yoyo: true,
      repeat: -1,
    })
  }, [])

  return (
    <Box
      as={'svg'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 118.86 98.88"
      {...props}
    >
      <defs>
        <style>{`.logo-cls-1{fill:#222;}.logo-cls-2{fill:#f2f2f2;}.flower{fill:#efe241;}`}</style>
      </defs>
      <title>WildLife Logo</title>
      <path
        className="logo-cls-1"
        d="M0,0H10.76l5,15.9c.42,1.65,1.69,8.71,1.69,8.71h.21s1.42-6.52,1.95-8.16L24.42,1.22h7.23L36.5,16.45c.48,1.71,2,8.16,2,8.16h.21s1.27-7.06,1.74-8.71l5-15.9H56.12L43.89,40.09H34.81l-5-15.9C29.27,22.42,28.17,17,28.17,17H27.9s-1,5.48-1.58,7.19L21.2,40.09h-9Z"
      />
      <path
        className="logo-cls-1"
        d="M63.77,1.48c3.37,0,5.16,1.84,5.16,4.32s-1.79,4.33-5.16,4.33S58.65,8.28,58.65,5.8,60.39,1.48,63.77,1.48ZM59.33,40.09V14H68.2V40.09Z"
      />
      <path className="logo-cls-1" d="M74,0h8.86V40.09H74Z" />
      <path
        className="logo-cls-1"
        d="M87.29,27.06c0-7.6,5.38-13.56,13.45-13.56a13.78,13.78,0,0,1,5.85,1.27l.11,0V0h8.81V30.12c0,1.79.42,2.53,1.68,3l1.38.43-2.43,6.8a10.34,10.34,0,0,1-2.06.27c-2.85-.32-5.22-1.53-6.43-4h-.11c-1.69,3-4.9,4-8.28,4C92.25,40.62,87.29,35.5,87.29,27.06ZM106.7,31.6v-10a11.89,11.89,0,0,0-4.49-.9c-3.79,0-6.11,2.37-6.11,6.33,0,3.48,2,6.33,5.69,6.33A8.06,8.06,0,0,0,106.7,31.6Z"
      />
      <path
        className="logo-cls-2"
        d="M12.93,48.72V86.19H33.71v12H1.07V48.72Z"
      />
      <path className="logo-cls-2" d="M39.79,98.23V66.05H50.73V98.23Z" />
      <path
        className="logo-cls-2"
        d="M81.89,58.36A13.55,13.55,0,0,0,78,57.65a5.46,5.46,0,0,0-3.71,1.1c-.85.79-1.18,2.09-1.18,4.3v3h9.58V75H73.09V98.23H62.15V75H56.81V66.05h5.34v-4.5c0-7.36,4.43-12.83,14.13-12.83a19.21,19.21,0,0,1,7,1.24Z"
      />
      <path
        className="logo-cls-2"
        d="M87.21,82.86c0-10.88,5.92-17.46,17.13-17.46,9.77,0,14.52,4.69,14.52,11.85a32.73,32.73,0,0,1-.78,6.84h-20c.06,4.24,2.08,6.45,7.68,6.45a29.53,29.53,0,0,0,11.14-2.15L118.15,96c-2.09,1.17-7,2.93-13.68,2.93C94.05,98.88,87.21,93.34,87.21,82.86Zm11.07-5h9.9s.07-.66.07-1.83c0-2.21-1.24-3.65-4.3-3.65C100.36,72.43,98.74,74.13,98.28,77.91Z"
      />
      <g transform={`matrix(1,0,0,1,-0.5,-0.5)`}>
        <path
          ref={flowerRef}
          className="flower"
          d="M58.23,61.21c-.68-.93-1.38-1.82-2.08-2.65,1.28-.3,2.59-.65,3.93-1.09,9.35-3,16-8.33,14.87-11.84s-9.64-3.9-19-.87c-1.1.36-2.15.75-3.17,1.16.12-1.31.18-2.67.19-4.07,0-9.83-3-17.8-6.67-17.8s-6.69,8-6.69,17.79c0,1.15,0,2.28.12,3.37-1.21-.51-2.48-1-3.82-1.44-9.34-3-17.84-2.66-19,.85s5.51,8.81,14.85,11.86c1.1.35,2.18.66,3.25.92-.87,1-1.72,2.06-2.55,3.19-5.78,8-8,16.15-5.06,18.32S37.5,76.4,43.28,68.45c.68-.93,1.31-1.87,1.89-2.8.68,1.13,1.42,2.27,2.24,3.41,5.77,8,12.87,12.64,15.86,10.48S64,69.17,58.23,61.21Z"
        />
      </g>
      <path
        className="logo-cls-1"
        d="M45.21,46.59c5.72,0,8.76,3.13,8.76,7.33s-3,7.33-8.76,7.33-8.67-3.13-8.67-7.33S39.49,46.59,45.21,46.59Z"
      />
    </Box>
  )
}

export default LogoImg
