/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

function Seo({
  description,
  shortTeaser,
  lang = 'en',
  meta,
  title,
  article,
  locationPathname,
  imagePath = '/images/wildlife-og-cover.png',
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            authorHandle
            siteUrl
          }
        }
      }
    `
  )

  const siteName = site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const shortTeaserDescription =
    shortTeaser || description || site.siteMetadata.description

  const openGraph = article
    ? [
        {
          property: `og:type`,
          content: 'article',
        },
        {
          name: 'article:section',
          content: 'article',
        },
        {
          name: 'article:published_time',
          content: article.createdDatetime,
        },
        {
          name: 'article:modified_time',
          content: article.modifiedDatetime,
        },
        {
          name: 'og:updated_time',
          content: article.modifiedDatetime,
        },
      ]
    : [
        {
          property: `og:type`,
          content: 'website',
        },
      ]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        ...openGraph,
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: siteName,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${imagePath}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.authorHandle,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: shortTeaserDescription,
        },
      ].concat(meta)}
    >
      <link
        rel="canonical"
        href={`${site.siteMetadata.siteUrl}${locationPathname}`}
      />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
