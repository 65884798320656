import { Header } from '@local/shared'
import React from 'react'
import Footer from 'src/components/Footer'
import LogoImg from 'src/components/images/LogoImg'
import Box from '@local/shared/components/System/Box'

const PageLayout = ({ children, type = 'full' }) => {
  return (
    <>
      {type === 'full' && (
        <Header logo={() => <LogoImg height={1} />} bgcolor={'green.0'} />
      )}
      {type === 'condensed' && (
        <Box height={'6em'} textAlign={'center'} bgcolor={'blue.0'} py={'1em'}>
          <Box display={'inline-block'} height={1}>
            <LogoImg height={1} />
          </Box>
        </Box>
      )}
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default PageLayout
